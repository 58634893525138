import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import VueAxios from "vue-axios";
import "unfetch/polyfill";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import * as CONFIG from "@/config";
var hash = require("object-hash");

Vue.use(Vuex);
Vue.use(VueAxios, axios);

function newAbortSignal(timeoutMs) {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeoutMs || 0);

  return abortController.signal;
}

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.cordova ? window.localStorage : window.sessionStorage,
    }),
  ],
  state: {
    authenticated: false,
    cordova: window.cordova ? true : false,
    token: null,
    passwordHash: null,
    userData: null,
    region: null,
    channel: "off-trade",
    toc: null,
    updates: {},
    prevModified: 0,
    modified: 0,
    updatesReviewedAt: 0,
    appToken: null,
    appUserID: 0,
    packageTS: [],
    downloadsStartedCount: 0,
    eventsPool: [],
    eventsToSend: [],
    logEventTimer: null,
  },
  mutations: {
    clearState(state) {
      state.userData = state.token = null;
      state.authenticated = false;
      state.passwordHash = null;
      state.region = null;
      state.toc = null;
      state.updates = [];
      state.updatesReviewedAt = 0;
      state.modified = 0;
      state.prevModified = 0;
      state.packageTS = [];
      window.downloadsStartedCount = 0;
    },
    setTOC(state, { data }) {
      state.toc = data.toc;
      if (data.modified)
        store.commit("updateModificationTime", { ts: data.modified });
    },
    setUpdates(state, { updates, updatesReviewedAt }) {
      state.updates = updates;
      if (updatesReviewedAt) state.updatesReviewedAt = updatesReviewedAt;
      if (window.cordova) {
        window.cordova.plugins.notification.badge.set(updates.length);
      }
    },
    appendUpdates(state, { updates }) {
      state.updates.concat(updates);
    },
    removeObsoleteUpdates(state, { ids }) {
      ids.forEach(function (id) {
        store.commit("deletePageFromUpdates", { pageID: id });
      });
    },
    deletePageFromUpdates(state, { pageID }) {
      let findDeep = function (data, value) {
        if (Array.isArray(data)) {
          let ind = data.indexOf(value);
          if (ind >= 0) {
            data.splice(ind, 1);
          }
        } else {
          for (let i = 0; i < Object.keys(data).length; i++) {
            findDeep(data[Object.keys(data)[i]], value);
          }
        }
      };
      findDeep(store.state.updates, pageID);
    },
    setActiveRegion(state, { region }) {
      console.log("setActiveRegion ", region);
      state.region = region;
      this.dispatch("logEvent", {
        type: "SetRegion",
        region: region,
      });
    },
    setUserData(state, { token, userData, passwordHash }) {
      state.userData = userData;
      state.passwordHash = passwordHash;
      state.token = token;
      state.authenticated = true;
    },
    setAppToken(state, { appToken }) {
      state.appToken = appToken;
    },
    resetToken(state) {
      state.token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIxOTIuMTY4LjEuMTIzIiwiYXVkIjoiMTkyLjE2OC4xLjEyMyIsImlhdCI6MTU3NTk3MjY1MCwibmJmIjoxNTc1OTcyNjUwLCJleHAiOjE1NzYwNTkwNTAsInVzZXJJZCI6NTE0NjF9.7EJ9bdJ-NvzkrlHcQOeQ9YLtIag1ldJ-RBIjnMu9sf4";
    },
    setAppUserID(state, { appUserID }) {
      state.appUserID = appUserID;
    },
    clearAppToken(state) {
      state.appToken = null;
      state.appUserID = 0;
    },
    updateModificationTime(state, { ts }) {
      state.modified = ts;
    },
    setPackageTS(state, { ts }) {
      state.packageTS.push(ts);
    },
    clearPackageTS(state) {
      state.packageTS = [];
    },
    increaseDownloadsStartedCount(state) {
      window.downloadsStartedCount = state.downloadsStartedCount + 1;
    },
    decreaseDownloadsStartedCount(state) {
      window.downloadsStartedCount = state.downloadsStartedCount - 1;
    },
    resetAuth(state, { auth }) {
      if (!auth) state.userData = null;
      state.authenticated = auth;
    },
  },
  actions: {
    login({ commit }, { username, password, cordova, isJeansLogin, channel }) {
      return new Promise((resolve, reject) => {
        if (
          !window.navigator.onLine &&
          this.state.passwordHash &&
          this.state.passwordHash == hash(password)
        ) {
          if (this.state.modified !== 0) {
            commit("resetAuth", { auth: true });
            resolve();
          } else {
             reject();
          }
        } else {
          axios.defaults.baseURL = CONFIG.USERS_API_URL;
          axios.defaults.headers.Authorization = "";
          axios
            .post("/auth", {
              username: username,
              password: password,
              isJeansLogin: isJeansLogin,
              channel: channel,
              cordova: cordova,
            })
            .then((response) => {
              const token = response.data.jwt;
              if (!token) {
                reject(new Error("Ошибка авторизации"));
              }
              axios.defaults.headers.Authorization = "Bearer " + token;
              axios
                .get("/user")
                .then((response) => {
                  commit("setUserData", {
                    token: token,
                    passwordHash: hash(password),
                    userData: response.data,
                  });
                  resolve();
                })
                .catch((err) => {
                  reject(err);
                });
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },

    logout({ commit }, keepUser) {
      return new Promise((resolve, reject) => {
        if (typeof keepUser !== "undefined" && keepUser) {
          commit("resetAuth", { auth: false });
          resolve();
        } else {
          if (window.cordova)
            this.dispatch("unRegisterAppUser").finally(() => {
              commit("clearAppToken");
              commit("clearState");
              resolve();
            });
          else {
            commit("clearState");
            resolve();
          }
        }
      });
    },

    sendContentLastUpdateTime({ commit }, { ts }) {
      return new Promise(() => {
        axios.defaults.baseURL = CONFIG.USERS_API_URL;
        axios.defaults.headers.Authorization = "Bearer " + this.state.token;
        axios
          .put("/app", {
            auid: this.state.appUserID,
            ts: ts,
          })
          .finally(() => {});
      });
    },

    logEvent({ commit }, data) {
      return new Promise((resolve, reject) => {
        data.time = Date.now();
        this.state.eventsPool.push(data);
        if (window.navigator.onLine && !this.state.eventsToSend.length) {
          clearTimeout(this.state.logEventTimer);
          this.state.logEventTimer = setTimeout(
            function () {
              this.state.eventsToSend.push(...this.state.eventsPool);
              this.state.eventsPool.length = 0;
              axios
                .post(
                  "/event",
                  {
                    user_sessionid: this.state.token,
                    events: this.state.eventsToSend,
                  },
                  {
                    baseURL: CONFIG.STATS_API_URL,
                    headers: {
                      "X-API-KEY": "n8mWAgF7gCUrRCTPJmJgU",
                    },
                  }
                )
                .then((result) => {
                  this.state.eventsToSend.length = 0;
                  resolve(result);
                })
                .catch((err) => {
                  this.state.eventsPool.push(...this.state.eventsToSend);
                  this.state.eventsToSend.length = 0;
                  reject(err);
                });
            }.bind(this),
            5000
          );
        }
      });
    },

    registerAppUser({ commit }, { appToken }) {
      this.state.appToken = appToken;
      return new Promise(async (resolve, reject) => {
        axios.defaults.baseURL = CONFIG.USERS_API_URL;
        axios.defaults.headers.Authorization = "Bearer " + this.state.token;

        const appVersionNumber =
          await window.cordova.getAppVersion.getVersionNumber();
        const appPackageName =
          await window.cordova.getAppVersion.getPackageName();

        let registrationData = {
          token: appToken,
          uid: this.state.userData.userID,
          device: JSON.stringify(device),
          appDetails: JSON.stringify({
            appPackageName,
            appVersionNumber,
          }),
        };
        if (this.state.appUserID) registrationData.auid = this.state.appUserID;
        axios
          .put("/app", registrationData)
          .then((response) => {
            const data = JSON.parse(response.data.message);
            if (data.auid) this.state.appUserID = data.auid;
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    unRegisterAppUser({ commit }) {
      return new Promise((resolve, reject) => {
        axios.defaults.baseURL = CONFIG.USERS_API_URL;
        axios.defaults.headers.Authorization = "Bearer " + this.state.token;
        axios
          .delete("/app", {
            data: {
              auid: this.state.appUserID,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    changePassword({ commit }, { password }) {
      return new Promise((resolve, reject) => {
        axios.defaults.baseURL = CONFIG.USERS_API_URL;
        axios.defaults.headers.Authorization = "Bearer " + this.state.token;
        axios
          .put("/user", {
            newPassword: password,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    restorePassword({ commit }, { name, email, isJeansLogin }) {
      return new Promise((resolve, reject) => {
        axios.defaults.baseURL = CONFIG.USERS_API_URL;
        axios.defaults.headers.Authorization = "Bearer " + this.state.token;
        axios
          .post("/user", {
            name: name,
            email: email,
            isJeansLogin: isJeansLogin,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    checkForMods({ commit }) {
      return new Promise((resolve, reject) => {
        axios.defaults.baseURL = CONFIG.CONTENT_API_URL;
        axios.defaults.headers.Authorization = "Bearer " + this.state.token;

        //  "before-checkformods, channel: " +
        //    this.state.channel +
        //    ",userRegion: " +
        //    this.state.userData.userRegion.map((a) => a.value) +
        //    ",ts: " +
        //    this.state.modified +
        //    ",token: " +
        //    this.state.token
        //);

        axios
          .get("/modified", {
            signal: newAbortSignal(10000),
            params: {
              channel: this.state.channel,
              userRegion: this.state.userData.userRegion.map((a) => a.value),
              //currentUpdates: this.state.updates,
              updatesReviewedAt: Math.max(
                store.state.modified,
                store.state.updatesReviewedAt
              ),
              ts: this.state.modified,
            },
          })
          .then((response) => {
            if (typeof response.data.IDsToDelete !== "undefined")
              commit("removeObsoleteUpdates", {
                ids: response.data.IDsToDelete,
              });
            const result =
              Number(response.data.modified) > Number(this.state.modified);

            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    fetchNewUpdates({ commit }) {
      return new Promise((resolve, reject) => {
        if (window.navigator.onLine) {
          axios.defaults.baseURL = CONFIG.CONTENT_API_URL;
          axios.defaults.headers.Authorization = "Bearer " + this.state.token;
          axios
            .get("/updates", {
              params: {
                updatesReviewedAt: window.cordova
                  ? this.state.updatesReviewedAt
                    ? Math.min(
                        this.state.updatesReviewedAt,
                        this.state.modified
                      )
                    : this.state.modified
                  : this.state.updatesReviewedAt ||
                    (this.state.updatesReviewedAt = this.state.modified),
              },
            })
            .then((response) => {
              this.commit("setUpdates", {
                updates: response.data,
              });
              resolve();
            })
            .catch((err) => {
              reject();
            });
        }
      });
    },

    fetchTOC({ commit }) {
      return new Promise((resolve, reject) => {
        if (window.cordova) {
          window.resolveLocalFileSystemURL(
            cordova.file.dataDirectory + "toc.json",
            function (fileEntry) {
              //  "path2file: " + cordova.file.dataDirectory + "toc.json"
              //);
              fileEntry.file(function (file) {
                var reader = new FileReader();
                reader.onloadend = function () {
                  resolve(this.result);
                };
                reader.onerror = function () {
                  reject(this.error);
                };
                reader.readAsText(file);
              });
            },
            function (e) {
              reject(e);
            }
          );
        } else if (window.navigator.onLine) {
          axios.defaults.baseURL = CONFIG.CONTENT_API_URL;
          axios.defaults.headers.Authorization = "Bearer " + this.state.token;
          axios
            .get("/toc", {
              params: {
                userRegion: this.state.userData.userRegion.map((a) => a.value),
                channel: this.state.channel,
              },
            })
            .then((response) => {
              store.commit("setTOC", {
                data: response.data,
              });
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          reject();
        }
      });
    },

    getOfflineContent({ commit }) {
      return new Promise((resolve, reject) => {
        axios.defaults.baseURL = CONFIG.CONTENT_API_URL;
        axios.defaults.headers.Authorization = "Bearer " + this.state.token;
        backgroundtask.start(() => {
          axios
            .post("/offline", {
              regions: JSON.stringify(
                this.state.userData.userRegion.map((a) => a.value)
              ),
              last: this.state.modified,
              multipart: 1,
            })
            .then((response) => {
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        });
      });
    },

    deletePackage({ commit }) {
      return new Promise((resolve, reject) => {
        axios.defaults.baseURL = CONFIG.CONTENT_API_URL;
        axios.defaults.headers.Authorization = "Bearer " + this.state.token;
        axios
          .delete("/offline", {
            data: { ts: this.state.packageTS },
          })
          .finally((response) => {
            commit("clearPackageTS");
            resolve(response);
          });
      });
    },
  },
});

export default store;
